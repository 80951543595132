import { useEffect, useRef, useState } from "react";
import { Link, generatePath, useParams, useSearchParams } from "react-router-dom";
import EntityTypes from "../../models/entityTypes";
import Project from "../../models/project";
import { SearchProps, api } from "../../store/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import { PartnerDetailsRoute } from "../partners/partnerDetailsScreen";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import Resource from "../../models/resource";
import ProjectResource from "../../models/projectResource";
import { Control, FormProvider, SubmitHandler, useFieldArray, useForm, useWatch } from "react-hook-form";
import { AttributeValueEditor } from "../../components/attribute/attributeValueEditor";
import { ProjectDetailsRoute } from "./projectDetailsScreen";
import RecordsList, { RecordFilter } from "../../components/records/recordsList";
import { format } from "../../helpers/format";
import QuickAddModal from "../../components/modal/quickAddModal";
import { NewReferenceEntity } from "../../components/reference/referenceDropdown";
import { ResourceDetailsRoute } from "../resources/resourceDetailsScreen";
import QuickViewModal from "../../components/modal/quickViewModal";
import GradeTypes, { AllGrades } from "../../models/gradeTypes";
import { fetchAttributes, selectConfig } from "../../store/configSlice";
import GradeUnit from "../../models/gradeUnit";
import SelectGradeUnit from "../../components/project/selectGradeUnit";
import { setTitle } from "../../util/useDocumentTitle";
import { ProjectResourceEditRoute } from "../projectResources/editScreen";
import { Modal } from "react-bootstrap";
import { Panel, PanelBody } from "../../components/panel/panel";

export const ProjectResourcesRoute = "/projects/:projectId/resources";

interface ProjectResourcesRouteParams {
  projectId?: number;
  id?: string;
}

export default function ProjectResourcesScreen() {
  const labels = useAppSelector(selectLabels);
  const label = labels.projectResource;
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [record, setRecord] = useState<Project>();
  const [error, setError] = useState<String>();
  const [defaultValue, setDefaultValue] = useState<ProjectResource>();
  const params = useParams<keyof ProjectResourcesRouteParams>();
  const [searchParams] = useSearchParams();

  const [isEditing] = useState(false);

  const [resources, setResources] = useState<Resource[]>([]);
  const [grade, setGrade] = useState<number>();
  const [gradeUnit, setGradeUnit] = useState<GradeUnit>();
  const [selectedResource, setSelectedResource] = useState<Resource[]>([]);
  const [recentlyAdded, setRecentlyAdded] = useState<ProjectResource[]>([]);
  const [showAddNew, setShowAddNew] = useState<boolean>(false);
  const [resourceSearchTerm, setResourceSearchTerm] = useState("");
  const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);
  const [showViewResource, setShowViewResource] = useState<boolean>(false);
  const [newOption, setNewOption] = useState<NewReferenceEntity>();
  const addButtonRef = useRef<HTMLButtonElement>(null);

  const dispatch = useAppDispatch();
  const config = useAppSelector(selectConfig);
  const attributes = config.attributes[EntityTypes.ProjectResource];
  useEffect(() => { dispatch(fetchAttributes(EntityTypes.ProjectResource)) }, [dispatch]);

  const form = useForm<ProjectResource>();

  const { register, handleSubmit, reset, formState: { errors }, control, setValue } = form;
  const { fields } = useFieldArray({
    name: "attributes",
    keyName: "attributeId",
    control
  });

  const onSubmit: SubmitHandler<ProjectResource> = data => {
    console.log(data);
    setError(undefined);
    const request: ProjectResource = {
      ...data,
      gradeUnitId: gradeUnit?.id,
      // name: data.name,
      //projectId: parseInt(params.projectId!),
      resourceId: selectedResource[0].id,
      resource: selectedResource[0],
      isActive: data.isActive,
      id: data.id,
      isAnchor: data.isAnchor,
      // grade: data.grade,
      // unit: data.unit,
      attributes: data.attributes?.map((attrib) => {
        return {
          id: attrib.id,
          boolValue: attrib.boolValue,
          dateValue: attrib.dateValue,
          intValue: attrib.intValue,
          stringValue: attrib.stringValue,
          choicesValue: (attrib.choicesValue ?
            (Array.isArray(attrib.choicesValue) ? attrib.choicesValue : [attrib.choicesValue])
            : undefined
          ),
        };
      })
    };
    console.log(request);
    setIsSaving(true);
    (isEditing ? api.projectResources.update(request) : api.projectResources.create(request))
      .then(() => {
        setRecentlyAdded(old => [request, ...old]);
        // clear form
        setSelectedResource([]);
        setResourceSearchTerm('');
        reset();
        setIsSaving(false);
      })
      .catch((reason) => {
        setError("Unable to create/update " + label.singular + ": " + reason);
        setIsSaving(false);
      });
  };

  useEffect(() => {
    dispatch(fetchAttributes(EntityTypes.ProjectResource)); // Load Attributes
    // if EDITING
    if (params.id) {
      setIsLoading(true);
      api.projectResources.get(parseInt(params.id!))
        .then((record) => {
          setDefaultValue(record);
          setIsLoading(false);
        })
        .catch((reason) => {
          console.error(reason);
          setError("Unable to load " + label.singular + ` #${params.id}`);
        });
    }
    else {
      if (searchParams.has('grade')) {
        setGrade(parseInt(searchParams.get('grade') ?? '0'));
      }
      // if (searchParams.has('unit')) {
      //   setDefaultUnit(searchParams.get('unit'));
      // }
      setDefaultValue({
        //grade: parseInt(searchParams.get('grade') ?? '0'),
        //unit: searchParams.get('unit') ?? undefined,
        isActive: true,
      });
    }
  }, [dispatch, label.singular, params.id, searchParams]);

  useEffect(() => {
    if (attributes && defaultValue) {
      reset({
        ...defaultValue,
        attributes: attributes.map((attrib) => {
          if (defaultValue?.attributes) {
            const attributeValue = defaultValue?.attributes?.find((t) => t?.id === attrib.id);
            if (attributeValue) {
              // console.warn('replacing field', attributeValue);
              return attributeValue;
            }
          }
          return {
            id: attrib.id,
          }
        }),
      }, {

      });
    }
  }, [attributes, defaultValue, reset]);

  useEffect(() => {
    // Load the attribute and set the form with the current values
    api.projects.get(parseInt(params.projectId!))
      .then((record) => {
        setRecord(record);
        setTitle([`Add ${labels.projectResource.plural}`, record.name!]);
      })
      .catch((reason) => {
        console.error(reason);
        setError("Unable to load " + labels.project.singular + ` #${params.projectId}`);
      });
  }, [labels.project.singular, labels.projectResource.plural, params.projectId]);

  const watchAttributes = useWatch({
    control: control,
    name: `attributes`
  });
  const watchIsAnchor = useWatch({
    control: control,
    name: 'isAnchor',
  });


  return (<div>
    <div className="d-flex align-items-center mb-3">
      <div>
        <h1 className="page-header mb-0">Add {labels.projectResource.plural}</h1>
        <ul className="breadcrumb">
          <li className="breadcrumb-item"><Link to={generatePath(PartnerDetailsRoute, { id: `${record?.partner?.id}` })}>{record?.partner?.name}</Link></li>
          <li className="breadcrumb-item">{labels.project.plural}</li>
          <li className="breadcrumb-item active"><Link to={generatePath(ProjectDetailsRoute, { id: `${record?.id}` })}>{record?.name}</Link></li>
        </ul>
      </div>
    </div>
    {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
    <div className="row">
      {/* <div className="col-md-4">
        <div className="card border-0 mb-4">
          <div className="card-header bg-dark text-white p-3 h6 m-0 d-flex align-items-center">
            Common {labels.projectResource.singular} Attributes
          </div>
          <div className="card-body">
          </div>
        </div>
      </div> */}
      <div className="col-md-8">
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="card border-0 mb-4">
              <div className="card-header bg-dark text-white p-3 h6 m-0 d-flex align-items-center">
                Add New {labels.projectResource.singular}
              </div>
              <div className="card-body">
                {showAdvancedSearch && <div className="row">
                  <div className="col-12">
                    <label className="form-label">{labels.resource.singular} (Advanced Search)</label>
                    </div>
                  </div>}
                <div className="row">
                  <div className="col-6">
                    <label className="form-label">{labels.resource.singular}</label>
                    <div className="d-flex flex-row">
                      <button type="button" className="btn btn-sm text-primary" onClick={() => setShowAdvancedSearch(() => true)}><i className="fa-sharp fa-magnifying-glass-plus"></i></button>
                      <AsyncTypeahead
                        className="flex-grow-1"
                        options={resources}
                        id="resourceId"
                        useCache={false}
                        labelKey={"name"}
                        filterBy={['name']}
                        allowNew={true}
                        newSelectionPrefix="Create new: "
                        selected={selectedResource}
                        multiple={false}
                        minLength={0}
                        clearButton={true}
                        delay={600}
                        // onInputChange={(text, e) => setResourceSearchTerm(text)}
                        isLoading={isLoading}
                        {...register(`resourceId` as const, { required: true })}
                        onChange={(selected) => {
                          const selectedEntities = selected as Resource[];
                          console.log('onChange', selected);
                          if (selectedEntities.length > 0) {
                            const asCustomOption = selectedEntities[selectedEntities.length - 1] as NewReferenceEntity;
                            if (asCustomOption.customOption) {
                              console.warn('customOption', selectedEntities[selectedEntities.length - 1]);
                              setNewOption(asCustomOption);
                              setShowAddNew(true);
                              return;
                            }
                            // const selectedIds = selectedEntities!.map(s => s.id ?? '')
                            setSelectedResource(selectedEntities);
                            setValue('resourceId', selectedEntities[0].id);
                            // field.onChange(selectedEntities);
                          }
                          else {
                            setSelectedResource([]);
                            setValue('resourceId', undefined);
                            // field.onChange();
                          }
                        }
                        }
                        onSearch={function (search: string): void {
                          setIsLoading(true);
                          setResourceSearchTerm(search);
                          api.resources.search({ search }).then((results) => {
                            setResources(results.records);
                            setIsLoading(false);
                          })
                            .catch((reason) => {
                              throw new Error(`Error searching for ${labels.resource.singular} : ${reason}`);
                            });
                        }}
                      />
                      {selectedResource?.length === 1 && <button type="button" className="btn btn-sm" onClick={() => setShowViewResource(() => true)}><i className="fa fa-eye"></i></button>}
                      <button type="button" className="btn btn-sm" onClick={() => setShowAddNew(() => true)}><i className="fa fa-plus"></i></button>
                    </div>
                    {selectedResource?.length === 1 && <QuickViewModal
                      record={selectedResource[0]}
                      entityType={EntityTypes.Resource}
                      show={showViewResource}
                      onCancel={() => setShowViewResource(false)}
                      footer={<><Link target="_new" to={generatePath(ResourceDetailsRoute, { id: `${selectedResource[0].id}` })}>View {labels.resource.singular} <i className="fa-solid fa-external-link"></i></Link></>}
                    />}
                  </div>
                  <div className="col-md-3">
                    <label className="form-label">Grade</label>
                    <select className={"form-control mb-5px"} value={grade} onChange={(e) => {
                      setGradeUnit(undefined);
                      setGrade(parseInt(e.currentTarget.value));
                      }}>
                      <option value=""></option>
                      {AllGrades.map((grade) => <option value={grade}>{labels.grades[grade]}</option>)}
                    </select>
                  </div>
                  <div className="col-md-3">
                    <label className="form-label">Unit</label>
                    {record && grade && <SelectGradeUnit project={record} grade={grade} onSelect={setGradeUnit} selected={gradeUnit} defaultUnit={searchParams.get('unit') ?? undefined} />}
                  </div>

                  <div className="col-md-3 d-flex flex-row">
                    <label className="form-label me-3">Is Anchor {label.singular}</label>
                    <div className="form-check form-switch mb-2">
                      <input className="form-check-input" type="checkbox" id="isAnchor" {...register("isAnchor")} />
                      <label className="form-check-label" htmlFor="isAnchor">{Boolean(watchIsAnchor) ? 'Yes' : 'No'}</label>
                    </div>
                  </div>

                  {fields?.map((field, index) => {
                    const attribute = attributes?.find(attrib => attrib.id === field.id);
                    if (attribute) {
                      console.log('field', attribute.name, field);
                      return (<div key={field.id} className={"col-md-4 mb-15px" + (attribute.isRequired ? " required" : "")}>
                        <label className="form-label col-form-label col-md-3">{attribute.name}</label>
                        <div className="col-md-9">
                          {field.choicesValue}
                          <AttributeValueEditor attribute={attribute} errors={errors} index={index} watch={watchAttributes} />
                        </div>
                      </div>
                      );
                    }
                    return <div key={field.id}></div>;
                  }
                  )}
                </div>
              </div>
              <div className="card-footer text-end">
                <button ref={addButtonRef} type="submit" className="btn btn-success" disabled={isSaving || isEditing || gradeUnit === undefined}>Add {labels.projectResource.singular}</button>
              </div>
            </div>
          </form>
        </FormProvider>
        <QuickAddModal
          show={showAddNew}
          entityType={EntityTypes.Resource}
          onCancel={() => setShowAddNew(false)}
          onSuccess={(added: Resource) => {
            console.warn('QuickAddModal.onSuccess', added);
            if (added) {
              debugger;
              setResources([added]);
              setSelectedResource([added]);
              setValue('resourceId', added.id);

              if (addButtonRef.current) {
                addButtonRef.current.focus();
              }
            }
            setShowAddNew(false);
          }}
          initialData={newOption as Resource}
        />
        {record?.id && gradeUnit && <RecentlyAdded key={recentlyAdded.length} projectId={record!.id!} gradeUnit={gradeUnit} control={control} />}
      </div>
    </div>
    <Modal fullscreen={true} show={showAdvancedSearch} onHide={() => setShowAdvancedSearch(false)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Advanced Search for {label.plural}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <RecordsList<Resource>
            defaultSearch={resourceSearchTerm}
            type={EntityTypes.Resource}
            filters={[
              { key: 'contains', label: 'Name Contains', api: (search: SearchProps) => api.resources.search({ ...search, active: true }) },
              { key: 'exact', label: 'Name Is Exactly', api: (search: SearchProps) => api.resources.search({ ...search, search: `"${search.search}"`, active: true }) },
              { key: 'startswith', label: 'Name Starts With', api: (search: SearchProps) => api.resources.search({ ...search, search: `"${search.search}`, active: true }) },
            ]}
            actions={(record) => <>
              <button className="btn btn-sm btn-outline-primary mx-1" onClick={() => {setSelectedResource([record]); setShowAdvancedSearch(false); }}>Select {label.singular}</button>
            </>}
          />
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-primary w-100px me-5px" onClick={() => setShowAdvancedSearch(false)}>Cancel/Close</button>
      </Modal.Footer>
    </Modal>
  </div>);
}


function RecentlyAdded({ projectId, gradeUnit, control }: { projectId: number, gradeUnit: GradeUnit, control: Control<ProjectResource> }) {
  const [filters, setFilters] = useState<RecordFilter<ProjectResource>[]>([]);
  const labels = useAppSelector(selectLabels);
  const [reloadKey, setReloadKey] = useState<string>();
  // const [unit, grade] = useWatch({
  //   control,
  //   name: ["unit", "grade"], // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
  // });
  //const [gradeUnitId] = useWatch({ control, name: 'gradeUnitId'});

  const onResourceRemoved = (resource: ProjectResource) => {
    setReloadKey(`removed-${resource.id}`);
  };

  useEffect(() => {
    console.log('unit/grade changed', gradeUnit, reloadKey);
    setFilters([
      { key: `grade-${gradeUnit.grade}`, label: `Grade ${labels.grades[gradeUnit.grade ?? GradeTypes.NA]} Unit ${gradeUnit.unit}`, api: (search: SearchProps) => api.projectResources.forProject({ ...search, projectId, grade: gradeUnit.grade, unit: gradeUnit.unit }) },
    ]);
  }, [projectId, labels.grades, reloadKey, gradeUnit]);

  if (gradeUnit && filters.length > 0) {
    return <RecordsList
      type={EntityTypes.ProjectResource}
      filters={filters}
      columns={[
        {
          label: "Is Anchor?",
          headerClassNames: 'text-end',
          callback: (record: ProjectResource) => <td className="text-end">{record?.isAnchor && <i className="fa-solid fa-anchor me-1"></i>}{record?.isAnchor ? 'Yes' : 'No'}</td>
        },
        {
          label: "Added",
          headerClassNames: 'text-end',
          callback: (record: ProjectResource) => <td className="text-end">{record?.created && format.ago(record?.created)} ago</td>
        },
        {
          label: "Actions",
          headerClassNames: 'text-end',
          callback: (record: ProjectResource) => <td className="text-end">
            <Link to={generatePath(ProjectResourceEditRoute, { projectId: `${record.project?.id}`, id: `${record.id}` })} className="btn btn-xs btn-outline-warning mx-1">Edit</Link>
            <RemoveResourceButton resource={record} onChange={onResourceRemoved} />
            <Link to={generatePath(ResourceDetailsRoute, { id: `${record.resourceId}` })} className="btn btn-xs btn-outline-primary mx-1">View</Link>
          </td>
        },
      ]}
    />;
  }

  return <p>Select a Grade and Unit to view recentlty added records</p>;
}

function RemoveResourceButton({ resource, onChange }: { resource: ProjectResource, onChange?: (resource: ProjectResource) => void }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();

  const onClick = () => {
    setIsLoading(true);
    const update = {
      ...resource,
      isActive: false,
    };
    api.projectResources.update(update)
      .then((updated) => {
        setIsLoading(false);
        if (onChange) {
          onChange(updated);
        }
      })
      .catch((reason) => {
        setIsLoading(false);
        setError(reason);
      });
  };
  if (isLoading) {
    return <button className="btn btn-xs btn-outline-danger mx-1" disabled={true}><i className="fa fa-spinner fa-spin"></i> Removing...</button>
  }
  if (error) {
    return <span className="alert alert-danger"><i className="fa fa-exclamation-triangle"></i> {error}</span>
  }
  return <button onClick={(e) => onClick()} className="btn btn-xs btn-outline-danger mx-1">Remove</button>;
}