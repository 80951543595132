import DropdownProfile from './dropdown/profile';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectSession } from '../../store/sessionSlice';
import { Link } from 'react-router-dom';

export default function ShareHeader() {
	// const theme = useAppSelector(selectTheme);
	const dispatch = useAppDispatch();

	return (
		<div id="header" className={'app-header '}>
			<div className="navbar-header">
				<Link to="/" className="navbar-brand"><span className="navbar-logo"></span> <b>Knowledge Maps</b>  v2.0</Link>
			</div>


			<div className="navbar-nav">
				{/* <SearchForm /> */}
				{/* <DropdownNotification /> */}

				{/* <DropdownProfile /> */}

			</div>
		</div>
	)

}
