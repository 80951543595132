import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import Partner from "../../models/partner";
import Project from "../../models/project";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import { api } from "../../store/api";
import { useEffect, useRef, useState } from "react";
import EntityTypeLabel from "../../components/label/entityTypeLabel";
import EntityTypes from "../../models/entityTypes";
import ProjectGrade from "../../models/projectGrade";
import GradeUnit from "../../models/gradeUnit";
import { Panel, PanelBody, PanelHeader } from "../../components/panel/panel";
import ProjectResource from "../../models/projectResource";
import * as Highcharts from 'highcharts';
import highchartsSankey from "highcharts/modules/sankey";
import highchartsDependencyWheel from "highcharts/modules/dependency-wheel";
import highchartsExporting from "highcharts/modules/exporting";
import highchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import { ReportUnit } from "../../models/reportUnit";
import { useSearchParams } from "react-router-dom";
import { setTitle } from "../../util/useDocumentTitle";

export const CoherencyReportRoute = "/reports/coherency";

export default function CoherencyReportScreen() {
  const labels = useAppSelector(selectLabels);
  const [searchParams, setSearchParams] = useSearchParams();
  const projectIdStr = searchParams.get('project');
  // const location = useLocation();

  // highchartsHeatmap(Highcharts);
  // highchartsTreemap(Highcharts);
  // highchartsTreegraph(Highcharts);
  highchartsExporting(Highcharts);
  highchartsSankey(Highcharts);
  highchartsDependencyWheel(Highcharts);
  highchartsMore(Highcharts);

  const [isLoading, setIsLoading] = useState(false);
  const [partner, setPartner] = useState<Partner>();
  const [partners, setPartners] = useState<Partner[] | undefined>([]);
  const [project, setProject] = useState<Project>();
  const [projects, setProjects] = useState<Project[] | undefined>([]);
  const [grade, setGrade] = useState<ProjectGrade>();
  const [grades, setGrades] = useState<ProjectGrade[]>([]);
  const [units, setUnits] = useState<GradeUnit[]>([]);
  const [unit, setUnit] = useState<GradeUnit>();

  const [projectResources, setProjectResources] = useState<ProjectResource[]>();

  const [error, setError] = useState<String>();

  useEffect(() => {
    if (projectIdStr) {
      api.projects.get(parseInt(projectIdStr)).then((project) => {
        setPartner(project.partner);
        setProject(project);
      });
    }
  }, [projectIdStr]);

  useEffect(() => {
    if (partner) {
      api.projects.forPartner(partner.id!).then(setProjects);
    }
    else {
      setPartners([]);
      setGrades([]);
      setUnits([]);
      setProject(undefined);
      setUnit(undefined);
      setGrade(undefined);
    }
  }, [partner]);

  useEffect(() => {
    setUnit(undefined);
    setGrade(undefined);
    if (project) {
      api.project(project.id!).grades().then(setGrades);
    }
    else {
      setGrades([]);
      setUnits([]);
    }
  }, [project]);

  useEffect(() => {
    setUnit(undefined);
    console.log("fetching units", project, grade);
    if (project?.id && grade?.grade) {
      api.project(project.id!).grade(grade!.grade).units().then(setUnits);
    }
    else {
      setUnits([]);
    }
  }, [grade, project]);

  useEffect(() => {
    if (project?.id && unit?.unit && grade?.grade) {
      setIsLoading(true);
      api.project(project.id).grade(grade!.grade).unit(unit.unit).resources().then((resources) => {
        setProjectResources(resources);
        setIsLoading(false);
      });
    }
    else {
      setProjectResources(undefined);
    }
  }, [grade, project?.id, unit]);

  // Title
  useEffect(() => {
    setTitle([
      project ? project.name : '',
      grade ? `Grade ${labels.grades[grade.grade]}` : '',
      unit ? `Unit ${unit.unit}` : '',
      `Coherency Report`,
    ]);
  }, [grade, labels.grades, project, unit]);

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <div>
        <h1 className="page-header mb-0">Coherency Reporting</h1>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">Reports</li>
          <li className="breadcrumb-item active">Coherency Report</li>
        </ul>
      </div>
    </div>
    {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
    <div className="row">
      <div className="col-md-3">
        <div className="card border-0 mb-4">
          <div className="card-header bg-none p-3 h6 m-0 d-flex align-items-center">
            Report Parameters
          </div>
          <div className="card-body">
            
            <div className="row mb-15px">
              <label className="form-label col-form-label col-xl-3 text-nowrap"><EntityTypeLabel entityType={EntityTypes.Project} singlular /></label>
              <div className="col-xl-9">
                <AsyncTypeahead
                  options={projects ?? []}
                  //disabled={partner === undefined}
                  id="projectDropDown"
                  labelKey={"name"}
                  filterBy={['name']}
                  selected={project ? [project] : undefined}
                  minLength={0}
                  isLoading={projects === undefined}
                  clearButton={true}
                  onSearch={function (search: string): void {
                    setProjects(undefined);
                    api.projects.search({ search }).then((result) => {
                      setProjects(result.records);
                    })
                      .catch((reason) => {
                        throw new Error("Error searching for " + labels.project.plural + ": " + reason);
                      });
                  }}
                  onChange={(selected) => {
                    console.warn('onChange', selected);
                    const selectedProjects = selected as Project[];
                    if (selectedProjects.length > 0) {
                      setProject(selectedProjects[0]);
                    }
                    else {
                      setProject(undefined);
                    }
                  }}
                />
              </div>
            </div>
            <div className="row mb-15px">
              <label className="form-label col-form-label col-xl-3 text-nowrap">Grade</label>
              <div className="col-xl-9">
                {(grades?.length ?? 0) > 0 ? <Typeahead
                  options={grades}
                  id="gradeDropDown"
                  // labelKey={"name"}
                  labelKey={(option) => {
                    const projectGrade = option as ProjectGrade;
                    return labels.grades[projectGrade.grade];
                  }}
                  filterBy={['name']}
                  selected={grade ? [grade] : undefined}
                  minLength={0}
                  isLoading={grades === undefined}
                  clearButton={true}
                  onChange={(selected) => {
                    console.warn('onChange grade', selected);
                    const selectedGrades = selected as ProjectGrade[];
                    if (selectedGrades.length > 0) {
                      setGrade(selectedGrades[0]);
                    }
                    else {
                      setGrade(undefined);
                    }
                  }}
                /> : <input className="form-control" disabled />}
              </div>
            </div>
            <div className="row mb-15px">
              <label className="form-label col-form-label col-xl-3 text-nowrap">Unit</label>
              <div className="col-xl-9">
                {(units?.length ?? 0) > 0 ? <Typeahead
                  options={units ?? []}
                  id="unitDropDown"
                  labelKey={'unit'}
                  filterBy={['unit']}
                  selected={unit ? [unit] : []}
                  minLength={0}
                  isLoading={units === undefined}
                  clearButton={true}
                  onChange={(selected) => {
                    console.warn('onChange', selected);
                    const selectedUnits = selected as GradeUnit[];
                    if (selectedUnits.length > 0) {
                      setUnit(selectedUnits[0]);
                    }
                    else {
                      setUnit(undefined);
                    }
                  }}
                /> : <input className="form-control" disabled />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-8">
        {project && unit && <ProjectUnitReportItem project={project} unit={unit} />}
      </div>
    </div>
  </div>);
}

type ProjectUnitReportProps = {
  project: Project;
  unit: GradeUnit;
}
function ProjectUnitReportItem(props: ProjectUnitReportProps) {
  const labels = useAppSelector(selectLabels);
  const { project, unit } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<ReportUnit[]>();


  useEffect(() => {
    if (unit) {
      setIsLoading(true);
      api.reports.unit(unit).data().then((data) => {
        setData(data);
        setIsLoading(false);
      });
    }
    else {
      console.warn('clearing anchor topic unity data');
      setData(undefined);
    }
  }, [unit]);

  return <Panel>
    <PanelHeader>{project.name} Grade {labels.grades[unit.grade]} Unit {unit.unit}</PanelHeader>
    {data?.map((anchorData) => <PanelBody isLoading={isLoading}>
      <AnchorTopicCoherency key={`anchor-${unit.id}`} data={anchorData} />
      <TopicCoherency key={`topic-${unit.id}`} data={anchorData} />
    </PanelBody>)}
  </Panel>
}

type AnchorTopicCoherencyProps = {
  // project: Project;
  // unit: GradeUnit;
  data: ReportUnit;
};
function AnchorTopicCoherency(props: AnchorTopicCoherencyProps) {
  const labels = useAppSelector(selectLabels);
  const { data } = props;
  const [isLoading, setIsLoading] = useState(true);
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>();
  //const [data, setData] = useState<ReportUnit[]>();

  useEffect(() => {
    if (data && data.anchor) {
      setIsLoading(true);
      //api.reports.heatmap(project.id!).heatmap(domain).then((heatmap) => {
      setChartOptions({
        title: {
          text: 'Anchor Topic Unity',
          //text: `Grade ${labels.grades[unit.grade]} Unit ${unit.unit}`
        },
        // subtitle: {
        //   text: `${project.name} Grade ${labels.grades[unit.grade]} Unit ${unit.unit}`,
        // },
        accessibility: {
          point: {
            valueDescriptionFormat: '{index}. From {point.from} to {point.to}: {point.weight} topics in common: {point.description}'
          }
        },
        legend: {
          // align: 'right',
          // verticalAlign: 'middle',
          // layout: 'vertical',
          // floating: true,
          shadow: true,
        },
        series: [
          {
            type: 'sankey',
            name: 'Topics in Common with Anchor',
            showInLegend: true,
            allowPointSelect: true,
            stickyTracking: true,
            // startAngle: -90,
            // minPointLength: 10,
            // minLinkWidth: 30,
            dataLabels: {
              color: '#333',
              style: {
                // textOutline: 'none'
              },
              // textPath: {
              //   enabled: true
              // },
              distance: 10,
              //format: '({point.value-1})'

            },
            nodes: [
              {
                id: data.anchor?.name,
                level: 0,
              },
              ...data.projectTopics!.map((projectTopic) => {
                return {
                  id: projectTopic.label,
                  level: 1,
                };
              }),
              ...data.anchor.topics.map(topic => ({
                id: topic,
                level: 1,
              })),
              ...data.resources.flatMap(resource => resource.topics).map((topic) => ({
                id: topic,
                level: 1,
              })),
              ...data.resources.map((resource) => ({
                id: resource.name,
                level: 2,
              })),
            ],
            keys: ['from', 'to', 'weight'],
            data: [
              ...data.anchor.topics.map(topic => {
                const matches = data.resources.filter(resource => resource.topics.includes(topic)).length;
                return {
                  from: data.anchor?.name,
                  to: topic,
                  weight: Math.max(1, matches),
                  description: 'Anchor matches ' + matches + ' resources by topic',
                };
              }),
              ...data.resources.flatMap(resource => resource.topics.map((topic) => {
                // let weight = allResources.filter(r => r.topics.includes(projectTopic.label!)).length + 1;
                return {
                  from: topic,
                  to: resource.name,
                  weight: 1,
                };
              })),
            ],
            //description: 'Number of topics',
            //data: data.resources.map(resource => (data.anchor?.topics ?? 0) - resource.topicsInCommon),
            //data: data.resources.map(resource => resource.topicsInCommon.length),
            //color: 'orange'
            tooltip: {
              valuePrefix: 'Matching ',
              valueSuffix: ' of ' + (data.anchor?.topics.length ?? 0) + ' topics<br />{point.description}',
              pointFormat: '{point.fromNode.name} → {point.toNode.name}<br/>.',
            }
          }
        ]

      });

      setIsLoading(false);
    }
    else if (data && (data.anchor == null)) {
      console.log('report has data, but no anchor!!', data);
      setIsLoading(true);
      //api.reports.heatmap(project.id!).heatmap(domain).then((heatmap) => {
      setChartOptions({
        title: {
          text: 'Resource Topic Unity',
          //text: `Grade ${labels.grades[unit.grade]} Unit ${unit.unit}`
        },
        subtitle: {
          text: 'Unit has no Anchor',
        },
        legend: {
          shadow: true,
        },
        yAxis: {
          title: {
            text: 'Number Topics in Common',
          },
          labels: {

            step: 1,
          },
          allowDecimals: false,
        },
        xAxis: {
          categories: data.resources.map(resource => resource.name),
          labels: {
            step: 1,
          },
        },
        series: [
          {
            type: 'bar',
            name: 'Topics in Common',
            showInLegend: true,
            allowPointSelect: true,
            stickyTracking: true,
            // startAngle: -90,
            // minPointLength: 10,
            // minLinkWidth: 30,
            dataLabels: {
              color: '#333',
              style: {
                // textOutline: 'none'
              },
              // textPath: {
              //   enabled: true
              // },
              // distance: 10,
              //format: '({point.value-1})'

            },
            data: data.resources.map(resource => resource.topicsInCommon.length),
          }
        ]

      });

      setIsLoading(false);
    }
    else {
      setChartOptions(undefined);
    }
  }, [data, labels.grades]);

  return <>
    {/* <h3>{domain.label}</h3> */}
    {isLoading && <p><i className="fa-solid fa-spin fa-spinner"></i> Loading Quality and Topic Unity data...</p>}
    {chartOptions && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartComponentRef} />}
  </>;
}


type TopicCoherencyProps = {
  // project: Project;
  // unit: GradeUnit;
  data: ReportUnit;
};
function TopicCoherency(props: TopicCoherencyProps) {
  // const labels = useAppSelector(selectLabels);
  const { data } = props;
  const [isLoading, setIsLoading] = useState(true);
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>();
  // const [data, setData] = useState<ReportUnit>();

  // useEffect(() => {
  //   if (unit) {
  //     setIsLoading(true);
  //     api.reports.unit(unit).data().then((data) => {
  //       setData(data);
  //       setIsLoading(false);
  //     });
  //   }
  //   else {
  //     setData(undefined);
  //     setChartOptions(undefined);
  //   }
  // }, [unit]);

  useEffect(() => {
    if (data) {
      setIsLoading(true);
      //api.reports.heatmap(project.id!).heatmap(domain).then((heatmap) => {
      const allResources = [...data.resources];
      if (data.anchor) allResources.push(data.anchor);
      setChartOptions({
        title: {
          text: 'Project Topic Coherency',
          //text: `Grade ${labels.grades[unit.grade]} Unit ${unit.unit}`
        },
        accessibility: {
          point: {
            valueDescriptionFormat: '{index}. From {point.from} to {point.to}: {point.weight} topics in common: {point.description}'
          }
        },
        legend: {
          enabled: false,
          align: 'right',
          verticalAlign: 'middle',
          layout: 'vertical',
          floating: true,
          shadow: true,
        },
        series: [
          {
            type: 'sankey',
            name: 'Coherency',
            showInLegend: true,
            //startAngle: -90,
            // minPointLength: 10,
            minLinkWidth: 30,
            dataLabels: {
              color: '#333',
              style: {
                // textOutline: 'none'
              },
              // textPath: {
              //   enabled: true
              // },
              distance: 10,
              //format: '({point.value-1})'
            },
            keys: ['from', 'to', 'weight'],
            nodes: [
              ...data.projectDomains!.map((tag) => {
                return {
                  id: tag.label,
                  // column: 1,
                  level: 0,
                };
              }),
              ...data.projectTopics!.map((projectTopic) => {
                return {
                  id: projectTopic.label,
                  level: 1,
                };
              }),
              ...allResources.flatMap(resource => resource.topics).map((topic) => ({
                id: topic,
                level: 1,
              })),
              ...allResources.map((resource) => ({
                id: resource.name,
                level: 2,
              })),
            ],
            data: [
              ...data.projectTopics!.filter(pt => pt.parentTag !== undefined)!.map((projectTopic) => {
                // const weight = allResources.filter(r => r.topics.includes(projectTopic.label!)).length + 1;
                return {
                  from: projectTopic.parentTag!.label,
                  to: projectTopic.label,
                  weight: allResources.filter(r => r.topics.includes(projectTopic.label!)).length + 1,
                };
              }),
              ...allResources.flatMap(resource => resource.topics.map((topic) => {
                // let weight = allResources.filter(r => r.topics.includes(projectTopic.label!)).length + 1;
                return {
                  from: topic,
                  to: resource.name,
                  weight: 1,
                };
              })),
            ],
            // .filter((resource) => resource !== undefined)
            // .map(resource => {
            //   return {
            //     from: resource.name,
            //     to: data.anchor?.name ?? 'NO ANCHOR',
            //     weight: resource.topicsInCommon.length,
            //     description: resource.topics.join("<br />"),
            //   };
            //   //return [resource.name, data.anchor?.name ?? 'NO ANCHOR', resource.topicsInCommon.length];
            //   // return [data.anchor?.name ?? 'NO ANCHOR', resource.name, resource.topicsInCommon.length];
            // }),
            //description: 'Number of topics',
            //data: data.resources.map(resource => (data.anchor?.topics ?? 0) - resource.topicsInCommon),
            //data: data.resources.map(resource => resource.topicsInCommon.length),
            //color: 'orange'
            tooltip: {
              valuePrefix: 'Matching ',
              valueSuffix: ' of ' + (data.anchor?.topics.length ?? 0) + ' topics<br />{point.description}',
              pointFormat: '{point.fromNode.name} → {point.toNode.name}<br/>.',
            }
          }
        ]

      });

      setIsLoading(false);
    }
    else {
      setChartOptions(undefined);
    }
  }, [data]);

  return <>
    {/* <h3>{domain.label}</h3> */}
    {isLoading && <p><i className="fa-solid fa-spin fa-spinner"></i> Loading report data...</p>}
    {chartOptions && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartComponentRef} />}
  </>;
}
