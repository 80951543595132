import { useEffect, useState } from 'react';
import './App.css';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { Outlet, useParams } from 'react-router-dom';
import { identity } from './store/sessionSlice';
import "react-datepicker/dist/react-datepicker.css";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import ShareHeader from './components/header/share-header';
import { api } from './store/api';

type ShareAppParams = {
  shareKey: string;
}

function ShareApp() {
  const { shareKey } = useParams<ShareAppParams>();

  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<String>();
  const session = useAppSelector(state => state.session);

  useEffect(() => {
    
    setIsLoading(true);

    if (!session.isLoggedIn) {
      // Redirect user to login screen
      console.warn("login required");
      setError(undefined);
      //return (<Navigate to={"/login?return=" + encodeURI(window.location.href)} replace />);
      api.identity.shared({ token: shareKey! })
        .then((response) => {
          if (response.error) {
            setError(response.error);
          }
          if (response.success) {
            dispatch(identity());
          }
          setIsLoading(false);
        })
        .catch((reason) => {
          setError("Unable to process shared access request: " + reason);
          setIsLoading(false);
        });
    }
    else {
      dispatch(identity());
    }
  }, [dispatch, session.isLoggedIn, shareKey]);

  // if (props.account === true && !session.account) {
  // 	console.warn("account required");
  // 	//debugger;
  // 	return (<Navigate to={AccountsScreenRoute} replace />);
  // }
  // console.warn("app", props.account);
  return (<div className={'app '}>
    <ShareHeader />
    <div className="m-3">
    {isLoading && <p><i className="fa-solid fa-spin fa-spinner"></i> Checking access...</p>}
    {error && <p><i className="fa-solid fa-exclamation-triangle text-danger"></i> ERROR: {error}</p>}
    {!isLoading && session.isLoggedIn && 
      <Outlet />
    }
    </div>
  </div>);
}

export default ShareApp;
