import { useEffect, useState } from "react";
import { Link, generatePath, useNavigate, useParams } from "react-router-dom";
import Attribute from "../../models/attribute";
import EntityTypes from "../../models/entityTypes";
import { SearchProps, api } from "../../store/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import { selectAccount } from "../../store/sessionSlice";
import RecordDetailsCard from "../../components/records/recordDetailsCard";
import Resource from "../../models/resource";
import { ResourcesRoute } from "./resourcesScreen";
import { ResourceProjectList } from "../../components/project/resourceProjectList";
import { ResourceEditRoute } from "./resourceEditScreen";
import { setResourceDetailsTab } from "../../store/uiSlice";
import EntityTypeLabel from "../../components/label/entityTypeLabel";
import RecordsList from "../../components/records/recordsList";
import Review from "../../models/review";
import { ReviewDetailsRoute } from "../reviews/reviewDetailsScreen";
import { format } from "../../helpers/format";
import { setTitle } from "../../util/useDocumentTitle";
import { usePermissions } from "../../util/usePermissions";
import { PermissionTypes } from "../../models/permissionTypes";
import AddTranslation from "../../components/resource/addTranslation";

export const ResourceDetailsRoute = "/resources/:id";

interface ResourceDetailsRouteParams {
  id?: string;
}

export default function ResourceDetailsScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const account = useAppSelector(selectAccount);
  const labels = useAppSelector(selectLabels);
  const [isLoading, setIsLoading] = useState(true);
  const [record, setRecord] = useState<Resource>();
  const [showAddTranslation, setShowAddTranslation] = useState(false);
  // const [contacts, setContacts] = useState<Contact[] | undefined>();
  // const [leases, setLeases] = useState<Project[] | undefined>();
  const [error, setError] = useState<String>();
  const params = useParams<keyof ResourceDetailsRouteParams>();
  const ui = useAppSelector(state => state.ui.resourceDetails);
  const { hasPermission } = usePermissions();
  const [numTranslations, setNumTranslations] = useState<number>();

  const onTranslationAdded = (e: Resource): void => {
    //setSortReviewers({ key: 'recent', label: 'Recently Added', api: (search: SearchProps) => api.reviewers.forProject({ ...search, projectId: record!.id!, }) });
    // TODO reload translations
  }

  useEffect(() => {
    // Load the attribute and set the form with the current values
    setIsLoading(true);

    api.resources.get(parseInt(params.id!))
      .then((record) => {
        setRecord(record);
        setTitle([`${labels.resource.singular} Details`, record.name!]);
        api.resources.translations(record).count().then(setNumTranslations);
      })
      .catch((reason) => {
        console.error(reason);
        setError("Unable to load " + labels.resource.singular + ` #${params.id}`);
      });
  }, [account, labels.contact.plural, labels.resource.singular, params.id]);

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <div>
        <h1 className="page-header mb-0">{record?.name} {record?.isActive === false && <span className="badge bg-danger">DISABLED</span>}</h1>
        <ul className="breadcrumb">
          <li className="breadcrumb-item"><Link to={generatePath(ResourcesRoute)}>{labels.resource.plural}</Link></li>
          <li className="breadcrumb-item active">Details</li>
        </ul>
      </div>
    </div>
    {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
    <div className="row">
      <div className="col-md-4">
        {record && <RecordDetailsCard
          record={record}
          type={EntityTypes.Resource}
          editRoute={ResourceEditRoute}
          displayAttributes={[
            {
              label: 'Translation of',
              hide: (record.sourceId ?? 0) <= 0,
              callback: (record: Resource) => <Link to={generatePath(ResourceDetailsRoute, { id: `${record?.sourceId}` })}>{record?.source?.name}</Link>,
            },
          ]}
        />}
      </div>
      <div className="col-md-8">
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button className={"nav-link " + (ui.tab === EntityTypes.ProjectResource ? "active" : "")} aria-current="page" onClick={(e) => dispatch(setResourceDetailsTab({ tab: EntityTypes.ProjectResource }))}>{labels.projectResource.plural}</button>
          </li>
          <li className="nav-item">
            <button className={"nav-link " + (ui.tab === EntityTypes.Review ? "active" : "")} onClick={(e) => dispatch(setResourceDetailsTab({ tab: EntityTypes.Review }))}><EntityTypeLabel entityType={EntityTypes.Review} /></button>
          </li>
          <li className="nav-item">
            <button className={"nav-link " + (ui.tab === EntityTypes.Resource ? "active" : "")} onClick={(e) => dispatch(setResourceDetailsTab({ tab: EntityTypes.Resource }))}>Translations {(numTranslations ?? 0) > 0 && <span className="badge bg-warning">{numTranslations}</span>}</button>
          </li>
        </ul>
        {ui.tab === EntityTypes.Resource && <div className="card border-0 mb-4">
          {record?.id && <RecordsList
            // showName={true}
            type={EntityTypes.Resource}
            filters={[
              { key: 'translations', label: 'All', api: (search: SearchProps) => api.resources.translations(record).search(search) },
            ]}
            columns={[
              // {
              //   label: partnerLabel.singular,
              //   callback: (record: ProjectResource) => <td><Link to={generatePath(PartnerDetailsRoute, { id: `${record.project?.partnerId}` })}>{record?.project?.partner?.name}</Link></td>
              // },
              {
                label: labels.reviewer.singular,
                sortKey: 'user',
                callback: (record) => <td>{record.userName}</td>,
              },
              {
                label: 'Created',
                sortKey: 'created',
                callback: (record) => <td>{format.ago(record.createdUtc!)}</td>,
              },
            ]}
            actions={(record: Review) => <>
              <button className="btn btn-sm btn-outline-primary mx-1" onClick={() => navigate(generatePath(ResourceDetailsRoute, { id: `${record.id}` }))}>Details</button>
            </>}

          //navigate(generatePath(ProjectEditRoute, { id: `${record.id}` }))
          />}
          {showAddTranslation
            ? <div className="card-footer">
              <AddTranslation resource={record!} onAdded={onTranslationAdded} />
            </div>
            :
            <div className="card-footer text-end">
              <button className="btn btn-outline-primary ms-auto" onClick={(e) => setShowAddTranslation(true)}><i className="fa fa-plus fa-lg me-2 ms-n2"></i> Add Translation</button>
            </div>}
        </div>}
        {ui.tab === EntityTypes.ProjectResource &&
          <div className="card border-0 mb-4">
            {record?.id && <ResourceProjectList resourceId={record.id} />}
          </div>}
        {ui.tab === EntityTypes.Review && record &&
          <div className="card border-0 mb-4">
            {record?.id && <RecordsList
              showName={false}
              type={EntityTypes.Review}
              filters={hasPermission(PermissionTypes.ResourceReviewsListAll)
                ?
                [
                  { key: 'all', label: 'All', api: (search: SearchProps) => api.reviews.forResource({ ...search, resourceId: record.id!, }) },
                  { key: 'mine', label: 'Submitted by me', api: (search: SearchProps) => api.reviews.forResource({ ...search, resourceId: record.id!, mine: true, }) },
                ]
                : [
                  { key: 'mine', label: 'Submitted by me', api: (search: SearchProps) => api.reviews.forResource({ ...search, resourceId: record.id!, mine: true, }) },
                ]}
              columns={[
                // {
                //   label: partnerLabel.singular,
                //   callback: (record: ProjectResource) => <td><Link to={generatePath(PartnerDetailsRoute, { id: `${record.project?.partnerId}` })}>{record?.project?.partner?.name}</Link></td>
                // },
                {
                  label: labels.reviewer.singular,
                  sortKey: 'user',
                  callback: (record) => <td>{record.userName}</td>,
                },
                {
                  label: 'Created',
                  sortKey: 'created',
                  callback: (record) => <td>{format.ago(record.createdUtc!)}</td>,
                },
              ]}
              actions={(record: Review) => <>
                <button className="btn btn-sm btn-outline-primary mx-1" onClick={() => navigate(generatePath(ReviewDetailsRoute, { id: `${record.id}` }))}>Details</button>
              </>}

            //navigate(generatePath(ProjectEditRoute, { id: `${record.id}` }))
            />}
          </div>}
      </div>
    </div>
  </div>);
}
