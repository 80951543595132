import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useFieldArray, useForm, useWatch } from "react-hook-form";
import { Link, generatePath, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AttributeValueEditor } from "../../components/attribute/attributeValueEditor";
import { Panel, PanelBody, PanelHeader } from "../../components/panel/panel";
import Attribute from "../../models/attribute";
import EntityTypes from "../../models/entityTypes";
import { api } from "../../store/api";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import { selectAccount } from "../../store/sessionSlice";
import Resource from "../../models/resource";
import { ResourceDetailsRoute } from "./resourceDetailsScreen";
import { fetchAttributes, selectConfig } from "../../store/configSlice";
import { setTitle } from "../../util/useDocumentTitle";
import ResourceCloneTypeahead from "../../components/modal/resourceCloneTypeahead";

export const ResourceEditRoute = "/resources/:id/edit";
export const ResourceCreateRoute = "/resources/create";

interface ResourceEditScreenParams {
  id?: number;
}

export default function ResourceEditScreen() {
  const navigate = useNavigate();
  const account = useAppSelector(selectAccount);
  const labels = useAppSelector(selectLabels);
  const label = labels.resource;
  const entityApi = api.resources;

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState<Resource>();
  const [translationOf, setTranslationOf] = useState<Resource>();
  const [error, setError] = useState<String>();

  const params = useParams<keyof ResourceEditScreenParams>();


  const dispatch = useAppDispatch();
  const config = useAppSelector(selectConfig);
  const attributes = config.attributes[EntityTypes.Resource];
  useEffect(() => { dispatch(fetchAttributes(EntityTypes.Resource)) }, [dispatch]);

  const fetchRecord = async (): Promise<Resource> => new Promise<Resource>((resolve) => {
    if (params.id) {
      setIsEditing(true);
      setIsLoading(true);
      entityApi.get(parseInt(params.id!))
        .then((record) => {
          setDefaultValue(record);
          setTitle([`Edit ${labels.resource.singular}`, record.name!]);
          resolve(record);
          setIsLoading(false);
        })
        .catch((reason) => {
          console.error(reason);
          setError("Unable to load " + label.singular + ` #${params.id}`);
        });
    }
    else {
      setTitle([`Create ${labels.resource.singular}`]);
      resolve({
        isActive: true,
        name: '',
      });
      // }
    }
  });



  const form = useForm<Resource>({
    defaultValues: fetchRecord,
  });

  const { register, handleSubmit, reset, formState: { errors }, control, setValue } = form;
  const { fields, replace } = useFieldArray({
    name: "attributes",
    keyName: "attributeId",
    control
  });

  const onSubmit: SubmitHandler<Resource> = data => {
    console.log(data);
    const request: Resource = {
      ...data,
      sourceId: translationOf?.id,
      attributes: data.attributes?.map((attrib) => {
        return {
          id: attrib.id,
          boolValue: attrib.boolValue,
          dateValue: attrib.dateValue,
          intValue: attrib.intValue,
          stringValue: attrib.stringValue,
          choicesValue: (attrib.choicesValue ?
            (Array.isArray(attrib.choicesValue) ? attrib.choicesValue : [attrib.choicesValue])
            : undefined
          ),
        };
      })
    };
    console.log(request);
    setIsLoading(true);
    (isEditing ? entityApi.update(request) : entityApi.create(request))
      .then((record) => {
        navigate(generatePath(ResourceDetailsRoute, { id: `${record.id}` }));
        setIsLoading(false);
      })
      .catch((reason) => {
        setError("Unable to load " + label.singular + ": " + reason);
        setIsLoading(false);
      });
  };


  // onLoad: set defaultValue with fetched record (if editing) or default
  useEffect(() => {
    dispatch(fetchAttributes(EntityTypes.ProjectResource)); // Load Attributes
    if (params.id) {
      setIsEditing(true);
      setIsLoading(true);
      entityApi.get(parseInt(params.id!))
        .then((record) => {
          setDefaultValue(record);
          setIsLoading(false);
        })
        .catch((reason) => {
          console.error(reason);
          setError("Unable to load " + label.singular + ` #${params.id}`);
        });
    }
    else {
      setDefaultValue({
        isActive: true,
        name: '',
      });
    }
  }, [dispatch, entityApi, label.singular, params.id]);

  // Initialize Form with Attributes and defaultValue
  useEffect(() => {
    if (attributes && defaultValue) {
      reset({
        ...defaultValue,
        attributes: attributes.map((attrib) => {
          if (defaultValue?.attributes) {
            const attributeValue = defaultValue?.attributes?.find((t) => t?.id === attrib.id);
            if (attributeValue) {
              // console.warn('replacing field', attributeValue);
              return attributeValue;
            }
          }
          return {
            id: attrib.id,
          }
        }),
      }, {

      });

      if (defaultValue.source) {
        setTranslationOf(defaultValue.source);
      }
    }
  }, [attributes, replace, defaultValue, reset]);

  const watchAttributes = useWatch({
    control: control,
    name: `attributes`
  });

  const onSourceSelected = (resource: Resource) => {
    if (resource) {
      setTranslationOf(resource);
    }
    // api.resources.get(resource.id!).then((resourceDetails) => {
    //   const { name, ...newResource } = resourceDetails;
    //   //setResourceSearchTerm(resource.name ?? '');
    //   //setDefaultValue({ ...newResource } as T);
    //   //setTranslatedFrom(resourceDetails);
    // });
  }

  return (<div>
    <ol className="breadcrumb float-xl-end">
      <li className="breadcrumb-item"><Link to="..">{label.plural}</Link></li>
      {isEditing && <li className="breadcrumb-item active">Edit</li>}
      {!isEditing && <li className="breadcrumb-item active">Create</li>}
    </ol>
    <h1 className="page-header">{label.singular} Details</h1>
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-8">
            <Panel className="card border-0" isLoading={isLoading}>
              <PanelBody className="card-body">
                {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">Name</label>
                  <div className="col-md-9">
                    <input type="text" className={"form-control mb-5px " + (errors.name ? 'is-invalid' : '')} {...register("name", { required: true })} />
                    {errors.name && <div className="invalid-feedback">This field is required</div>}
                  </div>
                </div>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">Translation of</label>
                  <div className="col-md-9">
                    {translationOf === undefined && <ResourceCloneTypeahead onSelected={onSourceSelected} />}
                    {translationOf && <div className="input-group">
                      <Link className="form-control readonly disabled" to={generatePath(ResourceDetailsRoute, { id: `${translationOf?.id}` })}><i className="fa-solid fa-eye"></i> {translationOf.name}</Link>
                      {/* <input type="text" className="form-control readonly disabled" value={translationOf.name} readOnly={true} disabled={true} /> */}
                      <button className="btn btn-danger" onClick={() => setTranslationOf(undefined)}><i className="fa-solid fa-times"></i></button>
                      </div>}
                  </div>
                </div>

                {fields?.map((field, index) => {
                  const attribute = attributes?.find(attrib => attrib.id === field.id);
                  if (attribute) {
                    console.log('field', attribute.name, field);
                    return (<div key={field.id} className={"row mb-15px" + (attribute.isRequired ? " required" : "")}>
                      <label className="form-label col-form-label col-md-3">{attribute.name}</label>
                      <div className="col-md-9">
                        <AttributeValueEditor attribute={attribute} errors={errors} index={index} watch={watchAttributes} />
                      </div>
                    </div>
                    );
                  }
                  return <div key={field.id}></div>;
                }
                )}

                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">Status</label>
                  <div className="col-md-9 pt-2">
                    <div className="form-check form-switch mb-2">
                      <input className="form-check-input" type="checkbox" id="isActive" {...register("isActive")} />
                      <label className="form-check-label" htmlFor="isActive">Active</label>
                    </div>
                  </div>
                </div>

                <div className="row mb-0">
                  <div className="offset-md-3 col-md-9 pt-2">
                    <button type="submit" className="btn btn-primary w-100px me-5px">Save</button>
                    <button type="button" onClick={() => navigate(-1)} className="btn btn-default w-100px">Cancel</button>
                  </div>
                </div>
              </PanelBody>
            </Panel>
          </div>
          <div className="col-md-4">
          </div>
        </div>
      </form>
    </FormProvider>
  </div>);
}
