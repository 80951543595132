import { RouteObject } from "react-router";
import { Outlet } from 'react-router-dom';

import App from "./App";
import ROUTES from "./constants/routes.json";
import { SettingsScreen, ROUTE as AccountSettingsScreenRoute } from "./screens/settings/settingsScreen";
import { Dashboard } from "./screens/dashboard/dashboard";
import { Error404 } from "./screens/error/error404";
import { HomeScreen, ROUTE as HomeScreenRoute } from "./screens/homeScreen";
import { Login } from "./screens/login";
import { UserProfile } from "./screens/user/userProfile";
import PartnersScreen, { PartnersRoute } from "./screens/partners/partnersScreen";
import PartnerDetailsScreen from "./screens/partners/partnerDetailsScreen";
import PartnerEditScreen from "./screens/partners/partnerEditScreen";
import { SettingsFieldsScreen } from "./screens/settings/fieldsScreen";
import { SettingsFieldScreen } from "./screens/settings/fieldScreen";
import { LabelsScreen } from "./screens/settings/labelsScreen";
import ProjectsScreen, { ProjectsRoute } from "./screens/projects/projectsScreen";
import ProjectEditScreen from "./screens/projects/projectEditScreen";
import ProjectDetailsScreen from "./screens/projects/projectDetailsScreen";
import ResourcesScreen, { ResourcesRoute } from "./screens/resources/resourcesScreen";
import ProjectResourcesScreen from "./screens/projects/projectResourcesScreen";
import ResourceDetailsScreen from "./screens/resources/resourceDetailsScreen";
import ResourceEditScreen from "./screens/resources/resourceEditScreen";
import ProjectResourceEditScreen from "./screens/projectResources/editScreen";
import ReviewsScreen, { ReviewsRoute } from "./screens/reviews/reviewsScreen";
import UsersScreen from "./screens/settings/usersScreen";
import UserEditScreen from "./screens/settings/userEditScreen";
import ProjectReviewerEditScreen from "./screens/projects/projectReviewerEditScreen";
import ReviewEditScreen from "./screens/reviews/reviewEditScreen";
import ReviewDetailsScreen from "./screens/reviews/reviewDetailsScreen";
import TagsScreen from "./screens/settings/tagsScreen";
import TagEditScreen from "./screens/settings/tagEditScreen";
import TagDetailsScreen from "./screens/settings/tagScreen";
import { TodoReviewsScreen } from "./screens/todo/todoReviewsScreen";
import UnitEvaluationsScreen, { UnitEvaluationsRoute } from "./screens/unitEvaluations/unitEvaluationsScreen";
import UnitEvaluationsDetailsScreen from "./screens/unitEvaluations/unitEvaluationsDetailsScreen";
import UnitEvaluationEditScreen from "./screens/unitEvaluations/unitEvaluationEditScreen";
import UserDetailsScreen from "./screens/settings/userDetailsScreen";
import UnitReportScreen from "./screens/reports/unitReportScreen";
import HeatmapReportScreen from "./screens/reports/heatMapScreen";
import ResourceQualityReportScreen from "./screens/reports/resourceQualityReport";
import CoherencyReportScreen from "./screens/reports/coherencyReport";
import ProjectFlatFileScreen from "./screens/projects/projectFlatFile";
import TagExportScreen from "./screens/settings/tagExport";
import ShareApp from "./ShareApp";
import ProjectShareScreen from "./screens/projects/projectShareScreen";

const Routes: RouteObject[] = [
	{
		path: '*',
		element: <Error404 />
	},
	{ path: ROUTES.LOGIN, element: <Login /> },
	{
		element: <ShareApp />,
		path: '/share/:shareKey',
		children: [
			{
				element: <ProjectShareScreen />,
				path: "project",
				children: [
					// Heatmap
					// Unit Report

				]
			}
		]
	},
	{
		element: <App />,
		//path: '',
		children: [

			{
				path: HomeScreenRoute,
				element: <HomeScreen />
			},
			{
				path: ROUTES.DASHBOARD,
				element: <Outlet />,
				children: [
					// { path: 'all-work', element: <AllWorkScreen /> },
					{ path: 'reviews', element: <TodoReviewsScreen /> },
					// { path: 'unitEvaluations', element: <DashboardReviewsScreen /> },
					{ index: true, element: <Dashboard /> },
				]
			},
			{
				path: AccountSettingsScreenRoute,
				element: <SettingsScreen />
			},
			{
				path: PartnersRoute,
				element: <Outlet />,
				children: [
					{ path: 'details/:id', element: <PartnerDetailsScreen /> },
					{ path: 'edit/:id', element: <PartnerEditScreen /> },
					{ path: 'create', element: <PartnerEditScreen /> },
					{ index: true, element: <PartnersScreen /> },
				]
			},
			{
				path: ProjectsRoute,
				element: <Outlet />,
				children: [
					{ path: ':projectId/reviewers/:id/edit', element: <ProjectReviewerEditScreen /> },
					{ path: ':projectId/reviewers/create', element: <ProjectReviewerEditScreen /> },
					{ path: ':projectId/resources/:id/edit', element: <ProjectResourceEditScreen /> },
					{ path: ':projectId/resources', element: <ProjectResourcesScreen /> },
					{ path: ':id/share', element: <ProjectShareScreen /> },
					{ path: ':id/export', element: <ProjectFlatFileScreen /> },
					{ path: ':id/edit', element: <ProjectEditScreen /> },
					{ path: 'create', element: <ProjectEditScreen /> },
					{ path: ':id', element: <ProjectDetailsScreen /> },
					{ index: true, element: <ProjectsScreen /> },
				]
			},
			{
				path: ResourcesRoute,
				element: <Outlet />,
				children: [
					{ path: ':id', element: <ResourceDetailsScreen /> },
					{ path: ':id/edit', element: <ResourceEditScreen /> },
					{ path: 'create', element: <ResourceEditScreen /> },
					{ index: true, element: <ResourcesScreen /> },
				]
			},
			{
				path: ReviewsRoute,
				element: <Outlet />,
				children: [
					{ path: ':id', element: <ReviewDetailsScreen /> },
					{ path: ':id/edit', element: <ReviewEditScreen /> },
					{ path: 'create', element: <ReviewEditScreen /> },
					{ index: true, element: <ReviewsScreen /> },
				]
			},
			{
				path: UnitEvaluationsRoute,
				element: <Outlet />,
				children: [
					{ path: ':id', element: <UnitEvaluationsDetailsScreen /> },
					{ path: ':id/edit', element: <UnitEvaluationEditScreen /> },
					{ path: 'create', element: <UnitEvaluationEditScreen /> },
					{ index: true, element: <UnitEvaluationsScreen /> },
				]
			},
			{
				path: '/user',
				element: <Outlet />,
				children: [
					{ path: 'profile', element: <UserProfile /> },
				]
			},
			{
				path: '/reports',
				element: <Outlet />,
				children: [
					{ path: 'heatmap', element: <HeatmapReportScreen /> },
					{ path: 'unit', element: <UnitReportScreen /> },
					{ path: 'resourceQuality', element: <ResourceQualityReportScreen /> },
					{ path: 'coherency', element: <CoherencyReportScreen /> },
				]
			},
			{
				path: 'settings/*',
				element: <Outlet />,
				children: [
					{
						path: 'users', element: <Outlet />, children: [
							{ path: ':id/details', element: <UserDetailsScreen /> },
							{ path: ':id/edit', element: <UserEditScreen /> },
							{ path: 'create', element: <UserEditScreen /> },
							{ index: true, element: <UsersScreen /> },
						]
					},
					{
						path: 'fields', element: <Outlet />, children: [
							{ path: 'edit/:id', element: <SettingsFieldScreen /> },
							{ path: 'create', element: <SettingsFieldScreen /> },
							{ index: true, element: <SettingsFieldsScreen /> },
						]
					},
					{
						path: 'tags', element: <Outlet />, children: [
							{ path: ':parentTagId/create', element: <TagEditScreen /> },
							{ path: ':id', element: <TagDetailsScreen /> },
							{ path: ':id/edit', element: <TagEditScreen /> },
							{ path: ':id/export', element: <TagExportScreen /> },
							{ path: 'create', element: <TagEditScreen /> },
							{ index: true, element: <TagsScreen /> },
						]
					},
					{ path: 'labels', element: <LabelsScreen /> },
					{ index: true, element: <SettingsScreen /> },
				]
			},
		]
	},

];

export default Routes;