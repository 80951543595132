import { useEffect, useState } from "react";
import { Link, createSearchParams, generatePath, useParams } from "react-router-dom";
import Partner from "../../models/partner";
import EntityTypes from "../../models/entityTypes";
import { api } from "../../store/api";
import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import { selectAccount } from "../../store/sessionSlice";
import { PartnerEditRoute } from "./partnerEditScreen";
import { ProjectList } from "../../components/project/projectList";
import { ProjectCreateRoute } from "../projects/projectEditScreen";
import RecordDetailsCard from "../../components/records/recordDetailsCard";
import { setTitle } from "../../util/useDocumentTitle";

interface PartnerDetailsRouteParams {
  id?: bigint;
}

export const PartnerDetailsRoute = "/partners/details/:id";
export default function PartnerDetailsScreen() {
  const account = useAppSelector(selectAccount);
  const labels = useAppSelector(selectLabels);
  const [isLoading, setIsLoading] = useState(true);
  const [record, setRecord] = useState<Partner>();
  const [error, setError] = useState<String>();
  const params = useParams<keyof PartnerDetailsRouteParams>();

  useEffect(() => {
    // Load the attribute and set the form with the current values
    setIsLoading(true);

    api.partners.get(parseInt(params.id!))
      .then((record) => {
        setRecord(record);
        setIsLoading(false);
      })
      .catch((reason) => {
        console.error(reason);
        setError("Unable to load " + labels.partner.singular + ` #${params.id}`);
      });
  }, [account, labels.contact.plural, labels.partner.singular, labels.project.plural, params.id]);

  // Title
  useEffect(() => {
    setTitle([
      `${labels.partner.singular} Details`,
      record?.name,
      ]);
  }, [labels.partner.singular, labels.review.singular, record?.name]);

  return (<div>
    <div className="d-flex align-items-top mb-3 justify-content-between">
      <div>
        <h1 className="page-header mb-0">{record?.name} {record?.isActive === false && <span className="badge bg-danger">DISABLED</span>}</h1>
        <ul className="breadcrumb">
          <li className="breadcrumb-item"><Link to="..">{labels.partner.plural}</Link></li>
          <li className="breadcrumb-item active">Details</li>
        </ul>
      </div>
      <div>
      </div>
    </div>
    {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
    {isLoading && <div className="alert alert-warning"><strong>Loading!</strong></div>}
    <div className="row">
      <div className="col-md-4">
        {record && <RecordDetailsCard
          record={record}
          type={EntityTypes.Partner}
          editRoute={PartnerEditRoute}
          displayAttributes={[
            // { label: labels.partner.singular, callback: (record: Resource) => <Link to={generatePath(PartnerDetailsRoute, { id: `${record?.partnerId}` })}>{record?.partner?.name}</Link>, },
          ]}
        />}
      </div>
      <div className="col-md-8">
        <div className="card border-0 mb-4">
          <div className="card-header bg-dark text-white p-3 h6 m-0 d-flex align-items-center">
            {labels.project.plural}
            <Link to={{ pathname: generatePath(ProjectCreateRoute), search: createSearchParams({ partnerId: record?.id?.toString() ?? "" }).toString() }} className="ms-auto text-decoration-none text-gray-500"><i className="fa fa-plus fa-lg me-2 ms-n2"></i> New {labels.project.singular}</Link>
          </div>
          <ProjectList partnerId={record?.id} />
        </div>
      </div>
    </div>
  </div>);
}
